.nepali-date-wrapper.disabled {
  pointer-events: none;
  /* Prevent interactions */
  opacity: 0.5;
  /* Visual indicator of being disabled */
}

.nepali-date-wrapper.disabled .form-control {
  cursor: not-allowed;
  /* Change cursor to indicate disabled state */
}

.nepali-date-wrapper table thead tr {
  background-color: rgb(186, 19, 26);
  color: white;
  font-size: 11px;
}

.nepali-date-picker .calender {
  padding-inline: 0 !important;
}